import React, {useContext, useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FaSignOutAlt, FaUserCircle } from 'react-icons/fa';
import { User } from "../../context";


const Header = ({signOut}) => {

  const [user, setUser] = useState("");
  const { fullUser } = useContext(User);

  useEffect(() => {
    if (fullUser) {
      setUser(fullUser.tokens.idToken.payload.email);
    }
  }, [fullUser]);


  return (
    <>
      <Navbar>
        <Link to="/" style={{textDecoration: "none"}}>
          <Logo>
            <img src="/logo.png" alt="logo" />
            <span style={{ color: 'white', fontSize: '20px', fontWeight: 'bold' }}>TOYOTA MEETINGS ASSISTANT</span>
          </Logo>
        </Link>
        <NavMenu>
          <UserEmail>{user}</UserEmail>
          <UserIcon>
            <FaUserCircle size={30} />
          </UserIcon>
          <LogoutIcon onClick={signOut}>
            <FaSignOutAlt size={30} />
          </LogoutIcon>
        </NavMenu>
      </Navbar>
    </>
  );
};

// Styled Components
const UserEmail = styled.p`
  color: white;
  margin-right: 20px;
  font-weight: bold;
`;

const Navbar = styled.div`
  background-color: ${({ theme }) => theme.colors.primaryColor};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  height: 60px;
  margin-bottom: 20px;
`;

const Logo = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 20px;
  img {
    height: 40px;
  }
`;

const NavMenu = styled.div`
  display: flex;
  align-items: center;
`;

const UserIcon = styled.div`
  color: white;
  margin-right: 20px;
  cursor: pointer;
`;

const LogoutIcon = styled.div`
  color: white;
  cursor: pointer;
`;

export default Header;
