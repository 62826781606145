import ApiClient from './api';
 
export default class TemplateDirectoryClient {
 
 
    constructor(){
        this.baseUrl = 'https://d3kfsucn40.execute-api.eu-south-1.amazonaws.com/prod/';
        this.client = new ApiClient(this.baseUrl)
    }
 
    async get_templates(){
        return this.client.do_get('templates/',{});
    }

    async get_template(template_key){
        return await this.client.do_get('templates/'+template_key,{}, false);
        // // Check the Content-Type header to determine how to handle the response
        // if (response.headers.get('Content-Type').includes('application/json')) {
        //     // If it's JSON, parse and return
        //     console.log("is json")
        //     return response.json();
        // } else {
        //     // Otherwise, treat it as text
        //     console.log("is text")
        //     return response.text();
        // }
    }
}