import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Container, Typography, Box, Button, FormControl, FormHelperText, Input, InputLabel, Select, Stack } from '@mui/material';
import { CC } from '../components/Form/Form';
import styled from 'styled-components';
import Theme from '../theme/theme';
import { IoMdDownload } from "react-icons/io";
import Modal from '../components/Modal/Modal';
import { FaExclamationTriangle } from "react-icons/fa";
import { User } from "../context";


import ApiClient from '../services/api/api';


export default function JobDetail() {
    const { job_id } = useParams();
    const navigate = useNavigate();

    const [file, setFile] = useState(null);
    const [ccValues, setCcValues] = useState([]);
    const [ccEmails, setCCEmails] = useState([]);

    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [job, setJob] = useState({
        project: '',
        cc: [],
        file: null,
        template: ''
    });
    const [attachments, setAttachments] = useState([]);

    const { fullUser } = React.useContext(User);
    const apiClient = new ApiClient();

    const get_user_id = () => {
        if (!fullUser) return -1;
        return fullUser.tokens.idToken.payload.email;
    }

    const fetchData = async () => {
        const data = await apiClient.getTaskInfo(job_id);
        setJob(data);

        setAttachments([
            { id: data.transcript_id, name: 'Transcript', url: '', date: data.creation_time.split("T")[0], type: "transcript" },
            { id: data.summary_id, name: 'Summary', url: '', date: data.summary_upload_time !== null ? data.summary_upload_time.split("T")[0] : data.creation_time.split("T")[0], type: "summary" }
        ])

        setCcValues(data.receivers_list || []);
    }

    const handleFileUpload = (event) => {
        setFile(event.target.files[0]);
    };

    const handleSaveConfirm = async (state) => {
        if (!!file) {
            const formData = new FormData();
            formData.append('task_id', job_id);
            formData.append('file', file);
            formData.append('file_type', "summary");
            await apiClient.uploadFile(job.project_id, get_user_id(), formData);
        }

        await apiClient.updateTask(job_id, { status: state, receivers_list: ccValues });

        if (state === "completed") {
            alert("Summary confirmed successfully");
            navigate("/");
        } else {
            alert("Chages saved successfully");
            window.location.reload();
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>

            <Modal isOpen={deleteModalIsOpen} onClose={() => setDeleteModalIsOpen(false)}>
                <ModalContainer>

                    <FaExclamationTriangle style={{ color: Theme.colors.primaryColor, fontSize: 120 }} />
                    <h2 style={{ color: Theme.colors.primaryColor }}>Do you really want to delete this request? Once deleted, it will not be possible to recover it.</h2>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 20 }}>

                        <Button variant="outlined" onClick={() => setDeleteModalIsOpen(false)}>CANCEL</Button>
                        <Button variant="contained">DELETE</Button>
                    </div>
                </ModalContainer>
            </Modal>


            <Container maxWidth="md" sx={{ py: 6 }}>
                <Typography variant="h4" gutterBottom>Summary Detail</Typography>

                <Stack sx={{ width: "100%" }} alignItems="center" justifyContent="space-evenly">
                    <Box sx={{ width: '100%', margin: 'auto', mt: 5, display: 'flex', alignItems: 'center', gap: 2 }}>
                        <Stack sx={{ width: '100%' }} alignItems="center" justifyContent="space-evenly">
                            <FormControl fullWidth margin="normal">
                                <p style={{ marginBottom: 20, marginLeft: 10, color: "#838383" }} id="project-select-label">Project</p>

                                <ProjectName>{job.project_id}</ProjectName>
                            </FormControl>

                            {/* <FormControl fullWidth margin="normal">
                <Box sx={{ width: '100%', margin: 'auto' }}>
                  <CC ccEmails={ccEmails} ccValues={ccValues} onChange={(cc) => setCcValues(cc)} disabled={false} />
                </Box>
                <FormHelperText>These email addresses will receive a copy of the meeting summary once it is confirmed</FormHelperText>
              </FormControl> */}

                            {/* <FormControl fullWidth margin="normal">

                <Input
                  id="file-upload"
                  type="file"
                  inputProps={{ accept: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' }}
                  onChange={handleFileUpload}
                  sx={{ mt: 2 }}
                  required={true}
                />
                <FormHelperText>Upload the .docx transcription file</FormHelperText>
              </FormControl> */}

                            <AttachsContainer attachments={attachments} project_id={job.project_id} user_id={get_user_id()} task_id={job_id} />

                            <BtnContainer>

                                <Button variant="outlined" onClick={() => navigate("/")}>CLOSE</Button>
                                {/* <Button variant="contained" onClick={() => handleSaveConfirm("ready")}>SAVE DRAFT</Button>
                <Button variant="contained" onClick={() => handleSaveConfirm("completed")}>CONFIRM SUMMARY</Button> */}
                            </BtnContainer>

                        </Stack>
                    </Box>
                </Stack>
            </Container>
        </>
    )
}

const ProjectName = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 56px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  padding: 10px;
  border-radius: 4px;
  color: #838383;
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  background: white;
  border-radius: 10px;
`;

const BtnContainer = styled.div`
  margin-top: 40px;
  display: grid;
  grid-template-columns: 1fr; # 1fr 1fr; # TODO: scommentare per riabilitare la griglia
  gap: 20px;
`;

const AttachsContainer = ({ attachments, project_id, user_id, task_id }) => {

    const apiClient = new ApiClient();

    //   const saveContent = async (url) => {
    //     console.log(url)
    //     const link = document.createElement('a');
    //     link.href = url;
    //     document.body.appendChild(link);
    //     link.target = "_blank";
    //     link.setAttribute("download", "file");
    //     link.click();
    //     setTimeout(() => {
    //       document.body.removeChild(link);
    //     }, 2000);
    //   }
    const saveContent = async (url) => {
        try {
            const response = await fetch(url, {
                method: "GET",
                credentials: "include", // Includi cookie se necessario
            });
            console.log(response);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            // Ottieni il nome del file dal backend (header Content-Disposition)
            // console.log(response.headers);
            // console.log(JSON.stringify(response.headers));
            const contentDisposition = response.headers.get("Content-Disposition");
            // console.log("contentDisposition", contentDisposition);
            let filename = "file"; // Default filename in caso non venga trovato nell'header

            if (contentDisposition && contentDisposition.includes("filename=")) {
                const match = contentDisposition.match(/filename="?([^"]+)"?/);
                if (match && match[1]) {
                    filename = match[1];
                }
            }

            // Crea un Blob dalla risposta e genera un URL temporaneo
            const blob = await response.blob();
            const urlBlob = window.URL.createObjectURL(blob);

            // Crea un elemento <a> per il download
            const link = document.createElement("a");
            link.href = urlBlob;
            link.download = filename; // Usa il nome determinato dal backend
            document.body.appendChild(link);
            link.click();

            // Cleanup
            setTimeout(() => {
                document.body.removeChild(link);
                window.URL.revokeObjectURL(urlBlob);
            }, 2000);
        } catch (error) {
            console.error("Error downloading the file:", error);
        }
    };

    return (
        <div style={{ width: "100%" }}>
            
            <AttachContainer>
                <span style={{ height: 50, width: 35 }} />
                <span style={{ width: "100%", fontWeight: "bold", color: Theme.colors.primaryColor }}>Attachment Name</span>
                <span style={{ width: 160, textAlign: "center", fontWeight: "bold", color: Theme.colors.primaryColor }}>Upload Date</span>
                <span style={{ width: 130, textAlign: "center", fontWeight: "bold", color: Theme.colors.primaryColor }}>Download</span>
            </AttachContainer>

            {attachments.map((attachment, index) => (
                <AttachItem attach={attachment} key={index + "_" + attachment.name} onDownload={async () => saveContent(await apiClient.downloadFile(project_id, user_id, { task_id: task_id, file_type: attachment.type }))} />
            ))}
        </div>
    )
}

const AttachItem = ({ attach, canDownload = true, onDownload = (x) => console.log(x) }) => {
    const iconStyle = {
        color: Theme.colors.primaryColor,
        cursor: "pointer",
        fontSize: 20,
        fontWeight: "bold"
    }
    const { id, name, date } = attach;

    return (
        <AttachContainer>
            <div style={{ height: 50, width: 35, background: Theme.colors.primaryColor }} />
            <div style={{ width: "100%" }}>{name}</div>
            <div style={{ width: 130, textAlign: "center" }}>{date}</div>
            {canDownload && <div style={{ width: 130, textAlign: "center" }}><IoMdDownload style={iconStyle} onClick={() => onDownload({ jobId: id, fileName: name })} /></div>}
        </AttachContainer>
    )
}

const AttachContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding: 10px 0;
  border-bottom: 1px solid red;
`;
