import React, { useEffect, useState } from 'react';
import { Hub } from 'aws-amplify/utils';
import { signInWithRedirect, signOut, getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';
import ListJobs from './pages/listJobs';
import JobDetail from './pages/jobDetail';
import { OverallCurrentPage, User } from './context';
import NewJobPage from './pages/newJob';
import useSessionStorageState from 'use-session-storage-state'
import { ThemeProvider } from "styled-components";
import Theme from './theme/theme';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import Header from './components/Header/header';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@mui/material/styles';
import Login from './pages/Login';

export default function App() {
    const [user, setUser] = useState(null);
    const [fullUser, setFullUser] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [error, setError] = useState(null);
    const [customState, setCustomState] = useState(null);
    const [currentPage, setCurrentPage] = useSessionStorageState('currentPage', { defaultValue: 'home' });

    const theme = createTheme({
        palette: {
            primary: {
                main: Theme.colors.primaryColor
            }
        }
    });

    useEffect(() => {
        const unsubscribe = Hub.listen('auth', ({ payload }) => {
            // eslint-disable-next-line default-case
            switch (payload.event) {
                case 'signInWithRedirect':
                    getUser();
                    break;
                case 'signInWithRedirect_failure':
                    setError('An error has occurred during the Oauth flow.');
                    break;
                case 'customOAuthState':
                    setCustomState(payload.data);
                    break;
            }
        });

        getUser();

        return unsubscribe;
    }, []);

    useEffect(() => {
        (async () => {
            if (!!user) {
                const fulluser = await fetchAuthSession();
                if (fulluser.tokens.idToken.payload['cognito:groups'].includes('iamidc_meetings_agent_admins')) {
                    setIsAdmin(true);
                }
                setFullUser(fulluser);
            }
        })();
    }, [user]);

    const getUser = async () => {
        try {
            const currentUser = await getCurrentUser();
            setUser(currentUser);
        } catch (error) {
            console.error(error);
        }
    };

    return (

        <>
            <MuiThemeProvider theme={theme}>
                <ThemeProvider theme={Theme}>
                    {!!fullUser ? <div className="App">

                        <OverallCurrentPage.Provider value={{ currentPage, setCurrentPage }}>
                            <User.Provider value={{ fullUser, isAdmin }}>
                                <BrowserRouter>
                                    <Header signOut={signOut}/>
                                    <Routes>
                                        <Route path="/" element={<ListJobs />} />
                                        <Route path="/newJob" element={<NewJobPage />} />
                                        <Route path="/jobs-list" element={<ListJobs />} />
                                        <Route path="/detail/:job_id" element={<JobDetail />} />
                                    </Routes>
                                </BrowserRouter>
                            </User.Provider>
                        </OverallCurrentPage.Provider>

                    </div> : (
                        <Login onStartLogin={() => signInWithRedirect()} />
                    )}
                </ThemeProvider>
            </MuiThemeProvider>
        </>

);
}

{/* <Login onStartLogin={() => signInWithRedirect({ provider: { custom: 'iamidc' } })} /> */}