class UserService {

    constructor(){
        console.log("US constr");
    }
    
    async listUserEmails() {
        console.log("US lue");
        return []
    }
}

export default UserService;