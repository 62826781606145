import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import JobInputForm from '../components/jobInputForm/jobInputForm';


const NewJobPage = () => {
  return (
    <Container maxWidth="md" sx={{ py: 6 }}>
      <Typography variant="h4" gutterBottom>
        Create a New Summary
      </Typography>
      <Box>
        <JobInputForm />
      </Box>

    </Container>
  );
};

export default NewJobPage;
