import React, { useEffect, useState } from 'react'
import styled from "styled-components"
import { Autocomplete, Box, Button, Chip, FormControl, FormHelperText, Input, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';

export default function ConfigurableForm({ config, onSubmit, onChange, editable = true }) {

    const [formState, setFormState] = useState(config)

    const localOnChange = (index, key, value) => {
        const newFormState = [...formState]
        newFormState[index] = { ...newFormState[index], [key]: value }
        newFormState[index].value = value
        setFormState(newFormState)
        const obj = generateObject(newFormState)
        onChange(obj)
    }

    const generateObject = (newFormState) => {
        const obj = {}
        newFormState.forEach(input => {
            obj[input.key] = input.value
        })
        return obj;
    }

    const localOnSubmit = (e) => {
        e.preventDefault()
        const obj = generateObject(formState)
        onSubmit(obj)
    }

    const getTag = (input, index) => {
        switch (input.type) {
            case "textarea":
                return <textarea disabled={!editable || !input.disabled} style={{ resize: "none" }} value={input.value}  {...input.props} onChange={(e) => localOnChange(index, input.key, e.target.value)} />
            case "select":
                return <select disabled={!editable || !input.disabled} value={input.value}  {...input.props} onChange={(e) => localOnChange(index, input.key, e.target.value)}>
                    {input.options.map((option, idx) => (
                        <option key={idx + "_" + option.value} value={option.value}>{option.label}</option>
                    ))}
                </select>

            case "file":
                return <input
                    disabled={!editable || !input.disabled}
                    type={input.type}
                    {...input.props}
                    onChange={(e) => localOnChange(index, input.key, e.target.files[0])}
                />
            case "cc":
                return <CC disabled={!editable || !input.disabled} ccEmails={input.ccEmails} ccValues={input.value} onChange={(newValue) => localOnChange(index, input.key, newValue)} />
            default:
                return <input
                    disabled={!editable || !input.disabled}
                    type={input.type}
                    value={input.value}
                    {...input.props}
                    onChange={(e) => localOnChange(index, input.key, e.target.value)}
                />
        }
    }

    return (
        <>
            {config && <FormContainer>
                {formState.map((input, index) => (
                    <div key={index + "_" + input.label}>
                        <div>
                            <h4 style={{ marginBottom: 10 }}>{input.label}</h4>
                        </div>
                        <div>
                            {getTag(input, index)}
                        </div>
                        {input.helper && <div>
                            <Helper>{input.helper}</Helper>
                        </div>}
                    </div>
                ))}
                <SubmitButton onClick={localOnSubmit} />
            </FormContainer>}
        </>
    )
}

const Helper = styled.span`
    font-size: 12px;
    color: #aaa;
    margin-top: 5px;
`;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;

  * {
    outline: none !important;
  }

  
input, textarea, select, option, button {
  width: 100%;
  padding: 10px 15px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  outline: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

input:focus, textarea:focus, select:focus, option:focus, button:focus {
  
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

input::placeholder, textarea::placeholder, select::placeholder, option::placeholder, button::placeholder {
  color: #aaa;
}

input:disabled, textarea:disabled, select:disabled, option:disabled, button:disabled {
  background-color: #f8f9fa;
  border-color: #ddd;
  cursor: not-allowed;
}
`;

const SubmitButton = styled.input.attrs({
    type: "button",
    value: "Submit"
})`
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    margin-top: 20px;
    cursor: pointer;
    border-radius: 5px;
    &:hover {
        background-color: #0056b3;
    }
    `;

export function CC({ ccEmails, ccValues, onChange, disabled }) {

    const [inputValue, setInputValue] = React.useState('')
    const [localCCValues, setCCValues] = React.useState([])

    useEffect(() => {
        setCCValues(ccValues)
    }, [ccValues])

    const handleInputChange = (_, newInputValue) => {
        setInputValue(newInputValue)
    }

    const handleAddCcValue = (_, newValue) => {
        if (newValue) {
            const newCCValues = [...ccValues, newValue]
            setCCValues(newCCValues)
            setInputValue('')
            onChange(newCCValues)
        }
    }

    const handleCcDelete = (cc) => () => {
        const newCCValues = ccValues.filter((ccValue) => ccValue !== cc)
        setCCValues(newCCValues);
        onChange(newCCValues)
    }

    return (
            <Box sx={{ width: '100%', margin: 'auto' }}>

                <Autocomplete
                    disabled={disabled}
                    value={null} // Value should be null to clear the input after selection
                    inputValue={inputValue}
                    onInputChange={handleInputChange}
                    onChange={handleAddCcValue}
                    options={ccEmails}
                    renderInput={(params) => (
                        <TextField
                            disabled={disabled}
                            {...params}
                            label="Add CC"
                            variant="outlined"
                        />
                    )}
                    freeSolo  // Allow arbitrary input not restricted to the options list
                />
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mt: 1 }}>
                    {localCCValues.map((cc, index) => (
                        <Chip
                            key={index + "_" + cc}
                            label={cc}
                            onDelete={handleCcDelete(cc)}
                        />
                    ))}
                </Box>
            </Box>
    )
}
