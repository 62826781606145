import ApiClient from './api';
 
export default class ProjectDirectoryClient {
 
 
    constructor(){
        this.baseUrl = 'https://domal2wlmj.execute-api.eu-south-1.amazonaws.com/prod/';
        this.client = new ApiClient(this.baseUrl)
    }
 
    // async get_paginated(path, query = {}, nextTokenField = 'next_token', dataField = 'data') {
    //     let allData = [];
    //     let nextToken = null;
    //     let i = 1;
 
    //     while (true) {
    //         console.log(`get_paginated ${i} next_token =`, nextToken);
    //         if (nextToken) query[nextTokenField] = nextToken;
    //         console.log(`${new Date().toISOString()} getting with nt = ${nextToken || 'None'}`);
 
    //         let responseData = await this.do_get(path, query);
    //         allData = allData.concat(responseData[dataField] || []);
    //         nextToken = responseData[nextTokenField];
    //         if (!nextToken) break;
 
    //         i++;
    //     }
 
    //     return allData;
    // }
 
    async get_paginated(path, query = {}, nextTokenField = 'next_token', dataField = 'data', requestToken = "") {
        let data = [];
        let nextToken = null;
 
        // console.log(`get_paginated ${i} next_token =`, nextToken);
 
        if (requestToken)
            query["next_token"] = requestToken;
 
        // console.log(`${new Date().toISOString()} getting with nt = nextToken || 'None'}`);
 
        let responseData = await this.client.do_get(path, query);
        
        data = responseData[dataField]
        nextToken = responseData[nextTokenField];
        return {
            "data": data,
            "nextToken": nextToken
        }
        // return data, nextToken;
    }
 
 
    async get_project_paginated(token){
        return this.get_paginated("projects", {}, "next_token", "projects", token);
    }
 
    async get_project_participants(projectName){
        return this.client.do_get('projects/'+projectName.toString()+'/participants',{});
 
 
    }
}