import { IconButton, Typography } from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import React from "react";
import ApiClient from "../../services/api/api";
import styled from 'styled-components';
import Theme from '../../theme/theme';

import { Link, useNavigate } from 'react-router-dom';


import { OverallCurrentPage } from './../../context';
import { User } from "../../context";
import { TiArrowRightThick } from "react-icons/ti";
import { IoMdDownload } from "react-icons/io";
import { TfiReload } from "react-icons/tfi";


export default function JobList({ project }) {

    const apiClient = new ApiClient();
    const [jobs, setJobs] = React.useState(null);
    const [mile, setMile] = React.useState(0);
    const { setCurrentPage } = React.useContext(OverallCurrentPage);
    const [currPage, setCurrPage] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(100);

    const { fullUser } = React.useContext(User);
    const navigate = useNavigate();

    const fetchData = async () => {
        if (!fullUser) return;
        const data = await apiClient.getPaginatedTasks(project, fullUser.tokens.idToken.payload.email, {
            num_page: currPage,
            page_size: pageSize,
        });

        setJobs(data);
    };


    React.useEffect(() => {
        fetchData();
    }, [fullUser, project, mile]);

    React.useEffect(() => {
        let to = setTimeout(() => {
            setMile(mile => mile + 1);
        }, 10000);
        return () => {
            clearTimeout(to);
        }
    }, []);

    const saveContent = (url) => {
        const link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        link.target = "_blank";
        link.setAttribute("download", "file.pdf");
        link.click();
        setTimeout(() => {
            document.body.removeChild(link);
        }, 2000);
    }


    const onDownload = async (jobId) => {
        const url = await apiClient.downloadFile(project, getCurrentUser(), { task_id: jobId, file_type: "summary" });
        saveContent(url);
    };

    const iconStyle = {
        color: Theme.colors.primaryColor,
        cursor: "pointer",
        fontSize: 20,
        fontWeight: "bold"
    }

    const getCurrentUser = () => {
        return fullUser.tokens.idToken.payload.email;
    }


    const getActionElement = (overallState, author, params) => {
        console.log(overallState)
        if (overallState === "completed") {
            return <GridActionsCellItem icon={<IoMdDownload style={iconStyle} />} onClick={() => onDownload(params.id)} label="Download" />;
        } else if ((overallState === "ready" || overallState === "summary_ready") && author === getCurrentUser()) {
            return <GridActionsCellItem icon={<TiArrowRightThick style={iconStyle} />} onClick={() => navigate("/detail/" + params.id)} label="Detail" />;
        }
        return <div />;
    }


    const columns = [
        { field: 'id', headerName: 'ID', flex: .2 },
        {field: "transcript_name", headerName: "Meeting name", flex: 1},
        // { field: 'fileName', headerName: 'File Name', flex: 1 },
        { field: 'status', headerName: 'Status', flex: .5 },
        { field: 'user_id', headerName: 'Creator', flex: .5 },
        { field: 'creation_time', headerName: 'Created', width: 175,valueFormatter: (value) => {
            const date = new Date(value);
            return date.toLocaleDateString('en-US', { 
              day: '2-digit', 
              month: '2-digit', 
              year: 'numeric', 
              hour: '2-digit', 
              minute: '2-digit' 
            });
          },
        },
        { field: 'action', headerName: 'Actions', width: 150, type: 'actions', getActions: (params) => [getActionElement(params.row.status, params.row.user_id, params)] }
    ];

    return <>
        <div style={{ height: '83vh', width: '100%' }}>
    
            <div style={{ display: 'flex', width: "100%", alignItems: "center", justifyContent: "space-between", marginBottom: 20 }}>
                <Typography variant="h6" gutterBottom>
                    Jobs 
                    {project ?
                        <span> for project <span style={{ fontWeight: "bold" }}>{project}</span></span> : "dall'utente"}
                    <IconButton onClick={() => setMile(mile + 1)}>
                        <TfiReload style={iconStyle} />
                    </IconButton>
                </Typography>

                <NewSummaryBtn onClick={() => setCurrentPage("newJob")} to="/newJob">New Summary</NewSummaryBtn>

            </div>

            {!!jobs && <DataGrid rows={jobs["tasks"] || []} columns={columns || []} pageSize={pageSize} getRowId={r => r.id} initialState={{
                sorting: {
                    sortModel: [{ field: 'creationTimestamp', sort: 'desc' }]
                }
            }} />}

        </div>
    </>
}

const NewSummaryBtn = styled(Link)`
    color: white;
    padding: 20px;
    background-color: ${props => props.theme.colors.primaryColor};
    outline: none;
    border: none;
    border-radius: 10px;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;

    &:hover {
        background-color: ${props => props.theme.colors.primaryColorDark};
    }
`;