import { Grid, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import JobList from "../components/jobList/jobList";

import ProjectDirectoryClient from "../services/api/project_api.js";
import Theme from "../theme/theme.js";
import { Api } from "@mui/icons-material";
import ApiClient from "../services/api/api.js";

const ListJobs = () => {
  const [tabValue, setTabValue] = useState("Nessuno");
  const [projects, setProjects] = useState([{ ProjectName: "Nessuno" }]);
  

  const handleTabChange = (event, newValue) => {
    if (newValue === load_new_projects_value) {
      projectDirectoryClient
        .get_project_paginated(nextProjectDirToken)
        .then(({ data: p, nextToken: token }) => {
          setProjects([...projects, ...p]);
          setProjectDirToken(token);
        });
    } else {
      setTabValue(newValue);
    }
  };

  const projectDirectoryClient = new ProjectDirectoryClient();
  const [nextProjectDirToken, setProjectDirToken] = useState("first");
  const load_new_projects_value = "load_new_projects";

  useEffect(() => {
    projectDirectoryClient
      .get_project_paginated()
      .then(({ data: p, nextToken: token }) => {
        setProjects([{ ProjectName: "Nessuno" }, ...p]);
        setProjectDirToken(token);
      });
  }, []);


  return (
    <Grid container style={{padding: 30}}>
      <Grid item xs={2}>
        <Typography style={{marginBottom: 20}} variant="h6"><span style={{fontWeight: "bold"}}>Browse for project</span></Typography>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={tabValue}
          onChange={handleTabChange}
          TabIndicatorProps={{
            style: {
              backgroundColor: Theme.colors.primaryColor
            }
          }}
        >
          {(projects || []).map((p) => (
            <Tab key={p.ProjectName} label={p.ProjectName} value={p.ProjectName} />
          ))}
          {!!nextProjectDirToken && (
            <Tab
              key={"Carica altri progetti..."}
              value={load_new_projects_value}
              label={"Carica altri progetti..."}
            />
          )}
        </Tabs>
      </Grid>
      <Grid item xs={10} sx={{ px: 5 }}>
        {tabValue && <JobList project={tabValue}/>}
      </Grid>
    </Grid>
  );
};

export default ListJobs;
