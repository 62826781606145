import * as React from 'react';
import { Autocomplete, Box, Button, Chip, FormControl, FormHelperText, Input, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import { OverallCurrentPage, User } from '../../context';
import ApiClient from '../../services/api/api';
// import ProjectService from '../../services/projects/projectService';
import ProjectDirectoryClient from "../../services/api/project_api.js";
import UserService from '../../services/users/userService';
import TemplateDirectoryClient from '../../services/api/template_api.js';
import { useNavigate } from "react-router-dom";
import { IoReload } from "react-icons/io5";
import styled, { keyframes } from 'styled-components';
import { GiPlainCircle } from "react-icons/gi";

export default function JobInputForm() {
    const [selectedProject, setSelectedProject] = React.useState('');
    const [selectedTemplate, setSelectedTemplate] = React.useState("");
    const [templates, setTemplates] = React.useState([]);
    const [ccValues, setCcValues] = React.useState([]);
    const [inputValue, setInputValue] = React.useState('');
    const [file, setFile] = React.useState(null);
    const userSvc = React.useMemo(() => new UserService(), []);
    const [projects, setProjects] = React.useState([]);
    const [ccEmails, setCCEmails] = React.useState([]);
    const [isSubmit, setIsSubmit] = React.useState(false);
    const [jobSubmitStep, setJobSubmitStep] = React.useState(0);
    const [confirmUpload, setConfirmUpload] = React.useState({});
    const apiClient = new ApiClient();
    const { fullUser } = React.useContext(User);

    const [isSelectOpen, setSelectOpen] = React.useState(false);

    const projectDirectoryClient = new ProjectDirectoryClient();
    const templateDirectoryClient = new TemplateDirectoryClient();
    const [nextProjectDirToken, setProjectDirToken] = React.useState("first");
    const load_new_projects_value = "load_new_projects"
    const navigate = useNavigate();



    React.useEffect(() => {
        projectDirectoryClient.get_project_paginated().then(({ data: p, nextToken: token }) => {
            setProjects([{ "ProjectName": "Nessuno" }, ...p]);
            setProjectDirToken(token);
            setSelectedProject("Nessuno");
        })
        userSvc.listUserEmails().then(e => setCCEmails(e));

        templateDirectoryClient.get_templates().then((templates) => {
            setTemplates(templates);
        });
    }, [userSvc]);

    const handleProjectChange = (event) => {
        const v = event.target.value
        if (v !== load_new_projects_value) {
            setSelectedProject(v);
            setSelectOpen(false);
        }
        else {
            projectDirectoryClient.get_project_paginated(nextProjectDirToken).then(({ data: p, nextToken: token }) => {
                setProjects([...projects, ...p]);
                setProjectDirToken(token);

                setTimeout(() => {
                    setSelectOpen(true);
                });


            })
        }
    };

    const handleInputChange = (_, newInputValue) => {
        setInputValue(newInputValue);
    };

    const handleAddCcValue = (_, newValue) => {
        // Avoid adding duplicates
        if (!ccValues.includes(newValue)) {
            setCcValues([...ccValues, newValue]);
        }
        setInputValue(''); // Clear input after selection
    };

    const handleCcDelete = (ccToDelete) => () => {
        setCcValues(ccValues.filter(cc => cc !== ccToDelete));
    };

    const fillCCPerProject = async () => {

        if (selectedProject === 'Nessuno' || selectedProject === "") return setCcValues([]);
        setCcValues(
            (await projectDirectoryClient.get_project_participants(selectedProject)).participants.map(
                x => x.email
            )
        )

    }

    React.useEffect(() => {
        fillCCPerProject();
    }, [selectedProject]);

    const handleFileUpload = (event) => {
        setFile(event.target.files[0]);
    };

    const handleClickProjects = (_) => {
        setSelectOpen(!isSelectOpen);
    }

    const handleTemplateChange = (e) => {
        setSelectedTemplate(e.target.value);
    }

    const getUserId = () => {
        if (!fullUser) return -1;
        return fullUser.tokens.idToken.payload.email;
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!file) {
            alert('Please select a file to upload.');
            return;
        }
        setIsSubmit(true);

        try {

            setJobSubmitStep(1);

            const userId = getUserId();

            const formData = new FormData();
            formData.append('file', file);
            formData.append('file_type', "transcript");

            const data = await apiClient.uploadFile(selectedProject, userId, formData);

            setJobSubmitStep(2);

            const payload = {
                transcript_id: data.file_id,
                template_id: selectedTemplate,
                receivers_list: ccValues,
            }

            console.log(payload);

            const res = await apiClient.registerTask(selectedProject, userId, payload);


            setJobSubmitStep(3);

            setTimeout(() => {
                navigate("/");
            }, 2000);

        } catch (e) {
            console.error(e);
            setJobSubmitStep(0);
            setIsSubmit(false);
            alert("An error occurred while submitting the request. Please try again later.");
        }

    };

    const messages = [
        "Request sent....",
        "Request Confirmed!",
        "Redirecting you to the main page..."
    ]

    const getMsgContent = (step) => {
        const res = [];
        for (let i = 0; i < step; i++) {
            res.push(
                <MsgBox>
                    <Dot />
                    <MsgText>{messages[i]}</MsgText>
                    {step === i + 1 && <Loader />}
                </MsgBox>
            )
        }
        return res;
    }


    return (
        <Stack sx={{ width: "100%" }} alignItems="center" justifyContent="space-evenly">

            {!isSubmit && (
                <Box sx={{ width: '100%', margin: 'auto', mt: 5, display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Stack sx={{ width: '100%' }} alignItems="center" justifyContent="space-evenly">
                        <FormControl fullWidth margin="normal">
                            <InputLabel id="project-select-label">Project</InputLabel>

                            <Select
                                labelId="project-select-label"
                                id="project-select"
                                value={selectedProject}
                                label="Project"
                                onClick={handleClickProjects}
                                onChange={handleProjectChange}
                                open={isSelectOpen}
                            >
                                {projects.map((p, pI) => (
                                    <MenuItem key={pI + "_" + p.ProjectName} value={p.ProjectName}>{p.ProjectName}</MenuItem>

                                ))}
                                {!!nextProjectDirToken && <MenuItem key={"Carica altri progetti..."} value={load_new_projects_value}>Carica altri progetti...</MenuItem>}
                            </Select>
                            <FormHelperText>Select the project the meeting belongs to.</FormHelperText>
                        </FormControl>

                        <FormControl fullWidth margin="normal">
                            <Box sx={{ width: '100%', margin: 'auto' }}>

                                <Autocomplete
                                    value={null} // Value should be null to clear the input after selection
                                    inputValue={inputValue}
                                    onInputChange={handleInputChange}
                                    onChange={handleAddCcValue}
                                    options={ccEmails}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Add CC"
                                            variant="outlined"
                                        />
                                    )}
                                    freeSolo  // Allow arbitrary input not restricted to the options list
                                />
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mt: 1 }}>
                                    {ccValues.map((cc, index) => (
                                        <Chip
                                            key={index + "_" + cc}
                                            label={cc}
                                            onDelete={handleCcDelete(cc)}
                                        />
                                    ))}
                                </Box>
                            </Box>
                            <FormHelperText>These email addresses will receive a copy of the meeting summary once it is confirmed</FormHelperText>
                        </FormControl>

                        <FormControl fullWidth margin="normal">
                            <InputLabel id="template-select-label">Template</InputLabel>

                            <Select
                                labelId="template-select-label"
                                id="template-select"
                                value={selectedTemplate}
                                label="Template"
                                onChange={handleTemplateChange}
                            >
                                {templates.map((t, tI) => (
                                    <MenuItem key={tI + "_" + t["name"]} value={t["path"]}>{t["name"]}</MenuItem>
                                ))}

                            </Select>
                            <FormHelperText>Choose a template based on the meeting type.</FormHelperText>
                        </FormControl>

                        <FormControl fullWidth margin="normal">

                            <Input
                                id="file-upload"
                                type="file"
                                inputProps={{ accept: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' }}
                                onChange={handleFileUpload}
                                sx={{ mt: 2 }}
                                required={true}
                            />
                            <FormHelperText>Upload the .docx transcription file</FormHelperText>
                        </FormControl>


                        <Button variant="contained" sx={{ mt: 3 }} onClick={handleSubmit} disabled={!file || !selectedProject || !selectedTemplate}>Create Summary</Button>
                    </Stack>
                </Box>)}
            {isSubmit && (
                <>
                    {getMsgContent(jobSubmitStep)}
                </>
            )}
        </Stack>
    );
}

const MsgBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    gap: 20px;
`;

const MsgText = styled.span`
    font-size: 1.5rem;
    font-weight: bold;
`;

const LoaderSpinKeyframes = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Loader = styled(IoReload)`
    height: 25px;
    width: 25px;
    animation: ${LoaderSpinKeyframes} 1s linear infinite;
    font-size: 2rem;
    color: ${({ theme }) => theme.colors.primaryColor};
    display: inline;
`;

const Dot = styled(GiPlainCircle)`
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.primaryColor};
    display: inline;
`;