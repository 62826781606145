const Theme = {
    colors: {
      primaryColor: "#EB0A1E",
      primaryColorDark: "#CC0000",
      bgColor: "#FFF",
    },
    fontColors: {
      primaryColor: "#000",
      secondaryColor: "#FFF"
    },
    fonts: ["sans-serif", "Roboto"],
    fontSizes: {
      small: "1em",
      medium: "2em",
      large: "3em"
    }
  }

export default Theme