import { fetchAuthSession } from 'aws-amplify/auth';

export default class ApiClient {


    constructor(baseUrl = 'https://vpoma.platform.kicode.it/bffMeetingsAssistant/apis/v1/') {
        this.baseUrl = baseUrl;
    }

    async get_jwt() {
        const currentUser = await fetchAuthSession();
        return currentUser.tokens.idToken.toString();
    }

    async do_get(path, query = {}, json_parse = true, get_raw = false) {

        // trasformo query da oggetto a stringa
        let queryString = [];
        for (const [key, value] of Object.entries(query)) {
            queryString.push(`${key}=${value}`);
        }
        queryString = queryString.join('&');

        let resp = await fetch(this.baseUrl + path + (queryString !== '' ? '?' + queryString : ''), {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + await this.get_jwt()
            },

        });

        if (get_raw) {
            return resp;
        }
        return json_parse ? await resp.json() : await resp.text();
    }

    async do_post(path, body, isFormData = false) {
        const h = {
            'Authorization': 'Bearer ' + await this.get_jwt(),
        }

        let resp = await fetch(this.baseUrl + path, {
            method: 'POST',
            headers: isFormData ? h : { ...h, 'Content-Type': 'application/json' },
            body: isFormData ? body : JSON.stringify(body)
        });
        return await resp.json();
    }
    async do_put(path, body) {
        let resp = await fetch(this.baseUrl + path, {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer ' + await this.get_jwt(),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        });
        return await resp.json();
    }

    // async do_prepare_upload({ selectedProject = "Nessuno", ccValues = [], fileName, selectedTemplate }){
    //     return await this.do_post('/jobs', {
    //         selectedProject, ccValues, fileName, template:selectedTemplate
    //     });

    // }
    // async do_confirm_upload({ jobId }){
    //     return await this.do_put('/jobs', {
    //         jobId
    //     });
    // }
    // async do_list_jobs({project}){
    //     if (!project || project === 'Nessuno')
    //         return await this.do_get('/jobs');
    //     else 
    //         return await this.do_get('/jobs', {project})
    // }

    // async do_get_project_directory(){
    //     return await this.do_get('/projects');
    // }

    // async do_prepare_download({ jobId }){
    //     return await this.do_get('/jobs/'+jobId);
    // }


    async registerTask(projectId, userId, taskPayload) {
        const path = `${projectId}/${userId}/tasks`;
        try {
            let response = await this.do_post(path, taskPayload);
            return response;
        } catch (error) {
            console.error("Error registering task:", error);
            throw error;
        }
    }

    async updateTask(taskId, taskPayload) {
        const path = `tasks/${taskId}`;
        try {
            let response = await this.do_put(path, taskPayload);
            return response;
        } catch (error) {
            console.error("Error updating task:", error);
            throw error;
        }
    }

    async getTaskInfo(taskId) {
        const path = `tasks/${taskId}`;
        try {
            let response = await this.do_get(path);
            return response;
        } catch (error) {
            console.error("Error getting task info:", error);
            throw error;
        }
    }

    async getPaginatedTasks(projectId, userId, pagePayload) {
        const path = `${projectId}/${userId}/tasks`;
        try {
            let response = await this.do_get(path, pagePayload);
            if (Object(response).hasOwnProperty('message')) {
                return { tasks: [], num_pages: 0 };
            }
            return response;
        } catch (error) {
            if (error.status === 404) {
                return { tasks: [], num_pages: 0 };
            }
            console.error("Error getting paginated tasks:", error);
            throw error;
        }
    }

    async getTemplates(projectId) {
        const path = `${projectId}/templates`;
        try {
            let response = await this.do_get(path);
            return response;
        } catch (error) {
            console.error("Error getting templates:", error);
            throw error;
        }
    }

    async getUsers(projectId) {
        const path = `${projectId}/users`;
        try {
            let response = await this.do_get(path);
            return response;
        } catch (error) {
            console.error("Error getting users:", error);
            throw error;
        }
    }

    async uploadFile(projectId, userId, filePayload) {
        const path = `${projectId}/${userId}/file`;
        try {
            let response = await this.do_post(path, filePayload, true);  // Pass true if it's form-data
            return response;
        } catch (error) {
            console.error("Error uploading file:", error);
            throw error;
        }
    }

    async downloadFile(projectId, userId, filePayload) {
        const path = `${projectId}/${userId}/file`;
        try {
            const response = await this.do_get(path, filePayload, false, true);
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            return url;
        } catch (error) {
            console.error("Error creating URL:", error);
            throw error;
        }
    }

}